import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FILE_NAME } from 'views/resources/constants';
import { useResourceForm } from 'views/single-resource/components/email-form-section/hooks';
import { ResourceFormContent } from 'views/single-resource/components/email-form-section/resource-form-content';
import { Container } from 'components/container';
import { Dialog } from 'components/dialog';
import { resolveDialogContent } from 'components/dialog/utilities';
import { DialogContent } from 'components/dialog-content';
import { HeaderSecond } from 'components/header-second';
import { FORM_STATUSES } from 'constants/form-statuses';
import { CONSTANTS } from 'constants/styles/constants';
import { useLoading } from 'hooks/use-loading';
import { bool, node, object, string } from 'prop-types';

const SSubContainer = styled.section`
    display: flex;
    gap: 4rem;

    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
        gap: 2rem;
    `}
`;

const SImageContainer = styled.div`
    flex: 1;
    order: 1;

    ${CONSTANTS.MEDIA.max1024`
        text-align: center;
        order: 2;
    `}
`;

const SFormContainer = styled.div`
    flex: 1;
    order: 2;
    padding-top: 5rem;

    ${CONSTANTS.MEDIA.max1024`
        text-align: center;
        order: 1;
    `}
`;

export const EmailFormSection = forwardRef(
    (
        {
            isEbookDownloadable = false,
            rootPath,
            image,
            formId,
            customDialogContent,
        },
        ref,
    ) => {
        const [formStatus, setFormStatus] = useState(FORM_STATUSES.IDLE);
        const dialogRef = useRef();
        const { isLoading, loadingStart, loadingStop } = useLoading();

        const updateFormStatus = useCallback((status) => {
            setFormStatus(status);

            if (status !== FORM_STATUSES.IDLE) {
                dialogRef?.current?.showModal();
            }
        }, []);

        const { control, formState, onSubmit } = useResourceForm({
            updateFormStatus,
            loadingStart,
            loadingStop,
            isEbookDownloadable,
            fileName: FILE_NAME.MEDICAL_DATA_PROTECTION,
        });

        const onClose = () => {
            if (
                formStatus === FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR
            ) {
                window.location.reload();
                return;
            }

            dialogRef?.current?.close();
            setFormStatus(FORM_STATUSES.IDLE);
        };

        return (
            <Container ref={ref}>
                <SSubContainer>
                    <SImageContainer>{image}</SImageContainer>
                    <SFormContainer>
                        <HeaderSecond topParagraph={`${rootPath}.topSubTitle`}>
                            <FormattedMessage
                                id={`${rootPath}.shortSubtitle`}
                            />
                        </HeaderSecond>
                        <ResourceFormContent
                            id={formId}
                            control={control}
                            formState={formState}
                            rootPath={rootPath}
                            isLoading={isLoading}
                            onSubmit={onSubmit}
                        />
                    </SFormContainer>
                </SSubContainer>
                <Dialog ref={dialogRef} testId="form_popup">
                    <DialogContent
                        {...resolveDialogContent({
                            formStatus,
                            customDialogContent,
                        })}
                        firstParagraphMargin
                        onClose={onClose}
                    />
                </Dialog>
            </Container>
        );
    },
);

EmailFormSection.propTypes = {
    isEbookDownloadable: bool,
    rootPath: string.isRequired,
    image: node.isRequired,
    formId: string.isRequired,
    customDialogContent: object.isRequired,
};

EmailFormSection.displayName = 'EmailFormSection';
