import React from 'react';
import { css } from 'styled-components';
import {
    ROOT_PATH,
    TILES_DATA,
} from 'views/single-resource/cross-platform-white-paper/constants';
import { SectionTilesSection } from 'modules/section-tiles-section';

export const Tiles = () => {
    return (
        <SectionTilesSection
            title={`${ROOT_PATH}.useCrossPlatformApp`}
            data={TILES_DATA}
            titleCss={css`
                font-weight: normal;
            `}
            sectionTilesCss={css`
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                row-gap: 4.2rem;
                column-gap: unset;
            `}
            sectionTileCss={css`
                min-width: 33.33%;
            `}
        />
    );
};
