import React, { useRef } from 'react';
import { EmailFormSection } from 'views/single-resource/components/email-form-section';
import { ImageForm } from 'views/single-resource/components/email-form-section/image-form';
import { LearnMoreAboutSection } from 'views/single-resource/cross-platform-white-paper/components/learn-more-about-section';
import { Main } from 'views/single-resource/cross-platform-white-paper/components/main';
import { Tiles } from 'views/single-resource/cross-platform-white-paper/components/tiles';
import { ROOT_PATH } from 'views/single-resource/cross-platform-white-paper/constants';
import { RequestSection } from 'modules/request-section';

import { crossPlatformWhitePaperDialogContent } from './constants';

export const CrossPlatformWhitePaperView = () => {
    const ref = useRef();
    const scrollTo = () => {
        ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    return (
        <>
            <Main onClick={() => scrollTo()} />
            <Tiles />
            <LearnMoreAboutSection onClick={() => scrollTo()} />
            <RequestSection />
            <EmailFormSection
                ref={ref}
                image={<ImageForm />}
                rootPath={ROOT_PATH}
                formId="cross-platform-white-paper-form"
                customDialogContent={crossPlatformWhitePaperDialogContent}
            />
        </>
    );
};
