import React from 'react';
import { ROOT_PATH } from 'views/single-resource/cross-platform-white-paper/constants';
import { Container } from 'components/container';
import { graphql, useStaticQuery } from 'gatsby';
import { RowWithTextImg } from 'modules/row-text-img';
import { func } from 'prop-types';

export const LearnMoreAboutSection = ({ onClick }) => {
    const image = useStaticQuery(graphql`
        {
            background: file(
                relativePath: {
                    eq: "cross-platform-white-paper/learn-more-about.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <Container>
            <RowWithTextImg
                title={`${ROOT_PATH}.about-section-title`}
                descriptions={[
                    `${ROOT_PATH}.about-section-desc.0`,
                    `${ROOT_PATH}.about-section-desc.1`,
                ]}
                img={image?.background}
                alt="About White Page"
                button={{
                    title: `${ROOT_PATH}.getForFree`,
                    onClick,
                    variant: 'outline',
                }}
                isReversed
            />
        </Container>
    );
};

LearnMoreAboutSection.propTypes = {
    onClick: func.isRequired,
};
