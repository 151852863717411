import React from 'react';
import { CrossPlatformWhitePaperView } from 'views/single-resource/cross-platform-white-paper';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/resources/cross-platform-white-paper.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const CrossPlatformWhitePaperPage = ({ location }) => {
    return (
        <Layout location={location} messages={messages}>
            <CrossPlatformWhitePaperView />
        </Layout>
    );
};

CrossPlatformWhitePaperPage.propTypes = {
    location: object.isRequired,
};

export default CrossPlatformWhitePaperPage;

export const Head = () => <SEO tags={TAGS.CROSS_PLATFORM_LANDING_PAGE} />;
