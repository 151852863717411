import React from 'react';
import { FormattedMessage } from 'react-intl';
import { css } from 'styled-components';
import { ROOT_PATH } from 'views/single-resource/cross-platform-white-paper/constants';
import { AnimatedMobileDevelopment } from 'components/animated-mobile-development';
import { Button } from 'components/button-new';
import { MainSectionBase } from 'modules/main-section/components/base';
import { func } from 'prop-types';

export const Main = ({ onClick }) => {
    return (
        <MainSectionBase
            icon={<AnimatedMobileDevelopment />}
            iconWrapperCss={css`
                max-width: 34.25rem;
            `}
            textWrapperCss={css`
                white-space: pre-wrap;
            `}
            titleId={`${ROOT_PATH}.title`}
            subtitles={[`${ROOT_PATH}.subtitleOne`, `${ROOT_PATH}.subtitleTwo`]}
            topSubTitleId={`${ROOT_PATH}.topSubTitle`}
            buttonsSection={
                <Button variant="outline" onClick={onClick}>
                    <FormattedMessage id={`${ROOT_PATH}.getForFree`} />
                </Button>
            }
        />
    );
};

Main.propTypes = {
    onClick: func.isRequired,
};
